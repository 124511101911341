const npsRoutes = [
    {
        path: '/:shortName/NPS',
        name: 'nps-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    {
        path: '/:shortName/NPS/:formSetId',
        name: 'nps-3question-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    {
        path: '/:shortName/NPSC',
        name: 'npsc-view',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    },
    // TODO: add back if we need this
    // {
    //     path: '/:shortName/NPSC/:formSetId',
    //     name: 'npsc-3question-view',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "nps-view" */ '@/views/shared/NpsView')
    // },
    {
        path: '/:shortName/NPS/ThankYou',
        name: 'nps-thank-you', //NPS url
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/:shortName/NPSC/ThankYou',
        name: 'npsc-thank-you',
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
]

for (const route of npsRoutes) {
    if (route.beforeEnter) continue

    route.beforeEnter = (to, from, next) => {
        to.params.kiosk = false
        // getting the value PatientSurvey or NPS so we know which form to go back to
        // need to get the path value at index `2` because with split, the 0 index is the first '/'
        if (to.path.indexOf('thank-you') === -1) {
            if (!to.params.routeType) to.params.routeType = to.path.split('/')[2].toLowerCase()
        }
        next(to.params)
    }
}

export {
    npsRoutes
}
