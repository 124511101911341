// A nice to have: a truly random selection of numbers
// because Math.random is more psuedo random but helps with our current scenario
const MAX_LENGTH = 5

// reference: https://www.30secondsofcode.org/js/s/sample-size
// tgus yses tge Fisher-Yates algorithm of shuffling the array and then pull the first n elements
const sampleSize = ([...arr], n = 1) => {
    let m = arr.length;
    while (m) {
        const i = Math.floor(Math.random() * m--);
        [arr[m], arr[i]] = [arr[i], arr[m]];
    }
    return arr.slice(0, n);
};


const getRandomSet = (arr) =>  {
    if (!arr?.length) return []
    if (arr.length <= MAX_LENGTH) return arr

    return sampleSize(arr, MAX_LENGTH)
}

const goBackToPresurvey = (router, isKiosk, shortname, query, routeName = '') => {
    let name = routeName
    if (!name) name = isKiosk ? 'Kiosk-PreSurveyView' : shortname ? 'PatientSurveyView' : 'PatientSurveyView-old'
    let route = {name: name}
    if (!isKiosk) route = {...route, query}
    router.push(route)
}


export {
    goBackToPresurvey,
    getRandomSet
}
