import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './plugins/installCompositionApi'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueAxios from 'vue-axios'
import device from 'vue-device-detector'
import VueMeta from 'vue-meta'
import {DefaultApolloClient} from '@vue/apollo-composable'
import { provide } from '@vue/composition-api'
import '@/utils/sentry'
import VuePhoneNumberInput from 'vue-phone-number-input'
import UUID from 'vue-uuid'

// font awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faComment, faSquare, faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons'
import { faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faPaperclip, faFilter, faChevronLeft, faChevronDown, faChevronRight, faCheck, faSignOutAlt, faLongArrowAltRight, faCheckSquare, faLock, faTimes, faCog, faCalendarAlt, faBan, faExclamationCircle, faBars, faEnvelope, faCaretDown, faTimesCircle, faSortUp, faSearch, faStar, faRedo, faMinusCircle, faCheckCircle, faCircle as fasCircle, faMinusSquare, faSpinner } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// project scss
import '@/assets/css/font-awesome.min.css'
import 'vuetify/dist/vuetify.css'
import '@/scss/main.scss'
import { getOrCreateProvider, client } from '@/vue-apollo'
import FullLayout from '@/views/FullLayout'
import EmptyLayout from '@/views/EmptyLayout'

//config.autoAddCss = false
library.add(faExclamationTriangle, faUserSecret, faPlus, faPencilAlt, faMapMarkerAlt, faPhoneAlt, faUser, faFileAlt, faQuestionCircle, faExternalLinkAlt, faTrashAlt, faDownload, faComment, faPaperclip, faFilter, faChevronLeft, faChevronDown, faChevronRight, faCheck, faSignOutAlt, faCheckSquare, faLongArrowAltRight, faLock, faTimes, faCog, faCalendarAlt, faCheckSquare, faBan, faExclamationCircle, faBars, faSquare, faEnvelope, faCaretDown, faTimesCircle, faSortUp, faSearch, faStar, faRedo, faCircle, faMinusCircle, faCheckCircle, faDotCircle, fasCircle, faMinusSquare, faSpinner )

// Add libraries
Vue.component('fa-icon', FontAwesomeIcon)

Vue.component('font-awesome-icon', FontAwesomeIcon) // cause vuetify requires it to be this tag
Vue.component('v-phone-input', VuePhoneNumberInput)

Vue.use(VueAxios, axios)
Vue.use(device)
Vue.use(VueMeta)
Vue.use(UUID)

// global components
Vue.component('default-layout', FullLayout)
Vue.component('empty-layout', EmptyLayout)

Vue.router = router

Vue.config.productionTip = false

const app = new Vue({
    apolloProvider: getOrCreateProvider(),
    router,
    store,
    vuetify,
    setup() {
        provide(DefaultApolloClient, client)
        provide('vuex-store', store)
    },
    render: h => h(App)
})

app.$mount('#app')
