<template lang="pug">
    .a-modal.fixed.inset-0.flex.flex-col.z-50.items-center.justify-center.active(
        :class="additionalClass"
        tabindex="0"
        v-if="$store.state.app.modal.isVisible"
    )
        div(
            class="a-modal__content top-0 lg:top-auto absolute bg-white-pure rounded-lg p-6 l-box-shadow"
            :class="modalContentClass"
        )
            component(:is="componentName" class="")

        .a-modal__screen-bg.w-full.h-full.inset-0(
            @click="bgClickHandler"
            @keyup.escape="bgClickHandler"
            @keyup.enter="bgClickHandler"
        )
</template>

<script>
import { mapState } from 'vuex'
import ModalDefault from '@/components/partials/ModalDefault'
import ModalShowIdleTimeout from '@/components/partials/ModalShowIdleTimeout'
import ModalShowScore from '@/components/partials/ModalShowScore'


export default {
    name: 'Modal',
    components: {
        ModalDefault, ModalShowIdleTimeout, ModalShowScore
    },
    methods: {
        bgClickHandler() {
            if (this.data && !this.data.keepOpen) this.$store.commit('MODAL_IS_VISIBLE', false)
        },
    },
    computed:{
        ...mapState({
            isVisible: state => state.app.modal.isVisible,
            componentName: state => state.app.modal.componentName,
            data: state => state.app.modal.componentData
        }),
        additionalClass() {
            if (this.data?.largeModal) return 'large-modal'
            else if (this.data?.modalClass) return this.data?.modalClass
            return ''
        },
        modalContentClass() {
            return this.data?.modalContentClass || ''
        }
    }
}
</script>
