import gql from 'graphql-tag'

const GET_TENANT = gql`
    query getTenant($tenantId: uuid = "") {
    tenant: form_builder_hasura_tenant_by_pk(tenant_id: $tenantId) {
        tenant_id
        name
        parent_tenant_id
        short_name
        parent_tenant {
            tenant_id
            name
        }
    }
}
`

const GET_TENANT_BY_SHORT_NAME = gql`query getTenantByShortName($shortName: String!) {
    tenant: form_builder_hasura_tenant(
        where: {short_name: {_eq: $shortName}}
    ) {
        tenant_id
        name
        parent_tenant_id
        parent_tenant {
            tenant_id
            name
        }
    }
}
`

const GET_TENANT_BY_SHORT_NAME_FOR_DYNAMIC_FORM = gql`query getTenantByShortName($shortName: String!) {
    tenantByShortName: form_builder_hasura_tenant(
        where: {short_name: {_eq: $shortName}}
    ) {
        tenant_id
        name
        parent_tenant_id
        parent_tenant {
            parent_tenant_id
            name
        }
    }
}
`

export {
    GET_TENANT,
    GET_TENANT_BY_SHORT_NAME,
    GET_TENANT_BY_SHORT_NAME_FOR_DYNAMIC_FORM
}
