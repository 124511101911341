<template>
    <header class="w-full bg-white-pure l-box-shadow py-1">
        <div class="container flex justify-start items-center">
            <img 
                :src="logoImage"
                alt="Quality Practice Accreditation" class="h-10 w-auto"
            />
            <h1 class="ml-4 text-grey-2 text-base">{{title}}</h1>
        </div>
    </header>
</template>

<script>
import mobileImage from '@/assets/images/mobile-logo.png'
import webImage from '@/assets/images/qpa-logo.png'

export default {
    name: 'GlobalHeader',
    components: {},
    props: {
        title: {
            type: String,
            default: 'Patient Feedback Survey'
        },
        tenantLogo: {
            type: String,
            default: ''
        }
    },
    methods: {
    },
    computed: {
       logoImage() {
          return this.tenantLogo ? this.tenantLogo : this.$device.mobile ? mobileImage : webImage
       }
    },
}
</script>

