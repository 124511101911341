export default {
    tenantId: '',
    formTitle: 'Patient Form',
    tenantLogo: '',
    modal: {
        isVisible: false,
        componentName: 'ModalDefault',
        componentData: {
            modalClass: ''
        },
    },
    testUser: {
        id: 1,
        userId: 1,
        firstName: 'Test',
        lastName: 'Ted',
        name: 'Test Ted',
        password: 'Random12',
        role: 'super-admin',
        email: 'support@codesource.com.au'
    },
    elementToEdit: {},
    editingForm: false,
    iframeMessage: {},
    iframeSource: {},
    constants: [],
    readOnly: false,
    staticChoices: {},
    isFullSurvey: false,
    thankYouRedirectQueryParams: {},
    isExternal: false,
    parentTenantId: '',
    previousRoute: '',
    questionMapping: {},
    tenant: {},
    saveTimeoutQueue: [], // list of callbacks to update - the latest one would be the latest change, so just take that one
    isSaving: null,
}
