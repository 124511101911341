import {
    MODAL_IS_VISIBLE,
    MODAL_DATA,
    MODAL_COMPONENT_NAME,
    SET_TENANT_ID,
    SET_FORM_TO_SAVE,
    SET_EDITING_FORM,
    SET_ELEMENT_TO_EDIT,
    SET_FORM_TITLE,
    SET_TENANT_LOGO,
    SET_IFRAME_MESSAGE,
    SET_IFRAME_SOURCE,
    SET_CATEGORY,
    SET_CONSTANTS,
    SET_READ_ONLY,
    SET_STATIC_OBJECT,
    SET_IS_FULL_SURVEY,
    SET_THANK_YOU_QUERY_PARAMS,
    SET_IS_EXTERNAL,
    SET_PARENT_TENANT_ID,
    SET_PREVIOUS_ROUTE,
    SET_QUESTION_MAPPING,
    SET_TENANT,
    UPDATE_QUESTION_MAPPING,
    ADD_TO_SAVE_TIMEOUT_QUEUE,
    CLEAR_SAVE_TIMEOUT_QUEUE, UPDATE_IS_SAVING
} from '@/store/base/mutationTypes'

export default {
    [MODAL_IS_VISIBLE](state, isVisible) {
        state.modal.isVisible = isVisible
    },
    [MODAL_DATA](state, componentData) {
        state.modal.componentData = { ...componentData }
    },
    [MODAL_COMPONENT_NAME](state, componentName) {
        state.modal.componentName = componentName
    },
    [SET_TENANT_ID](state, tenantId) {
        state.tenantId = tenantId
    },
    [SET_FORM_TO_SAVE](state, form) {
        state.formToSave = form
    },
    [SET_EDITING_FORM](state, editingForm) {
        state.editingForm = editingForm
    },
    [SET_ELEMENT_TO_EDIT](state, elementToEdit) {
        state.elementToEdit = elementToEdit
    },
    [SET_FORM_TITLE](state, formTitle) {
        state.formTitle = formTitle
    },
    [SET_TENANT_LOGO](state, logo) {
        state.tenantLogo = logo
    },
    [SET_IS_FULL_SURVEY](state, isFullSurvey) {
        state.isFullSurvey = isFullSurvey
    },
    [SET_CATEGORY](state, category) {
        state.category = category
    },
    [SET_IFRAME_MESSAGE](state, iframeMessage) {
        state.iframeMessage = iframeMessage
    },
    [SET_IFRAME_SOURCE](state, iframeSource) {
        state.iframeSource = iframeSource
    },
    [SET_CONSTANTS](state, constants) {
        state.constants = constants
    },
    [SET_READ_ONLY](state, readOnly) {
        state.readOnly = readOnly
    },
    [SET_STATIC_OBJECT](state, staticChoices) {
        state.staticChoices = staticChoices
    },
    [SET_THANK_YOU_QUERY_PARAMS](state, params) {
        state.thankYouRedirectQueryParams = {...params}
    },
    [SET_IS_EXTERNAL](state, isExternal) {
        state.isExternal = isExternal
    },
    [SET_PARENT_TENANT_ID](state, parentTenantId) {
        state.parentTenantId = parentTenantId
    },
    [SET_PREVIOUS_ROUTE](state, previousRoute) {
        state.previousRoute = previousRoute
    },
    [SET_QUESTION_MAPPING](state, questionMapping) {
        state.questionMapping = {...questionMapping}
    },
    [SET_TENANT](state, tenant) {
        state.tenant = {...tenant}
    },
    [UPDATE_QUESTION_MAPPING](state, {questionId, value}) {
        state.questionMapping[questionId] = value
    },
    [ADD_TO_SAVE_TIMEOUT_QUEUE](state, callback) {
        state.saveTimeoutQueue.push(callback)
    },
    [CLEAR_SAVE_TIMEOUT_QUEUE](state) {
        state.saveTimeoutQueue = []
    },
    [UPDATE_IS_SAVING](state, isSaving) {
        state.isSaving = isSaving
    },
}
