import {ErrorCodes} from '@/utils/enums'
import store from '@/store'
import camelcaseKeys from 'camelcase-keys'

const patientSurveyBeforeEnter = async (to, from, next) => {
    // checking to see if we need to force the load of the current route
    // and uf we have the short name, if we have the shortname already, then continue
    const forceLoad = to.query?.f
    let shortName = to.params?.shortName
    const isKiosk = to.params?.isKiosk || to.path.toLowerCase().indexOf('kiosk') > -1

    // if (forceLoad || shortName) return next()
    if (forceLoad || (forceLoad && shortName)) return next()

    // otherwise we get the tenant and redirect to the shortname
    // if we get to here, we get the tenantId
    const tenantId = to.query?.tenant

    if (!tenantId && !shortName) return next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})

    let foundTenant = null, tenant = null
    if (tenantId) {
        foundTenant = await store.dispatch('fetchTenant', tenantId)
        tenant = camelcaseKeys(foundTenant || {}, {deep: true})
        if (!shortName) shortName = tenant.shortName
    }

    // now we check to see if we have the sub route
    const formSetUrl = await store.dispatch('fetchFormSetUrl', { tenantId, isKiosk, shortName})
    // if we have a urlSubRoute then we go to that one, otherwise keep going with the original flow
    if (formSetUrl) return next(formSetUrl)
    //TODO: check if we need this
    else if (shortName) return next()

    if (!tenant && !shortName) next({name: 'error', params: {type: ErrorCodes.IncorrectRouteParams}})
    const name = isKiosk ? 'Kiosk-PreSurveyView' : shortName ? 'PatientSurveyView' : 'PatientSurveyView-old'
    return next({ name, params: { 'shortName': tenant?.shortName || shortName } })
}

export {
    patientSurveyBeforeEnter
}
