<template>
    <v-app :class="{'app-mobile': $device.mobile, 'ted-branding':isExternal, 'tenant-branding': isQpa}">
        <!-- Temporary button to show the modal for testing -->
        <v-main class="h-full" :class="{'items-start md:items-center': isQpa}">
            <div class="flex flex-col layoutHeight">
                <component :is="layout">
                    <SwitchLayout />
                </component>
            </div>
        </v-main>
        <component :is="modalActive" class="absolute" />
    </v-app>
</template>

<script>
import SwitchLayout from '@/views/SwitchLayout'
import {onMounted, computed, watch, ref} from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex-composition-helpers'
import Modal from '@/components/shared/Modal'

const { useActions, useGetters } = createNamespacedHelpers('')

export default {
    name: 'App',
    components: { SwitchLayout, Modal },
    metaInfo: {
        title: 'Patient Survey',
        meta: [
            {charset: 'utf-8'},
            {
                name: 'viewport',
                content: 'user-scalable=0, width=device-width, height=device-height, initial-scale=1, maximum-scale=1.0, minimum-scale=1.0'
            }
        ]
    },
    setup() {
        const { isVisible, isExternal, tenant } = useGetters(['isVisible', 'isExternal', 'tenant'])
        const { fetchStaticChoices } = useActions(['fetchStaticChoices'])
        const layout = ref('default-layout')

        onMounted(() => {
            fetchStaticChoices()
            if (isQpa && !isExternal.value) layout.value = `${getPrefix(true)}-layout`
        })

        const modalActive = computed(() => {
            return isVisible.value ? 'Modal': ''
        })

        const getPrefix = (hideHeader) => {
            return hideHeader ? 'empty' : 'default'
        }

        watch(() => isExternal.value, (newValue) => {
            const prefix = getPrefix(newValue || isQpa)
            layout.value =  `${prefix}-layout`
        })

        const isQpa = computed(() => {
            return tenant.value?.parentTenant?.name?.toLowerCase().indexOf('qpa') > -1
        })

        return {
            layout,
            isVisible,
            modalActive,
            isExternal,
            isQpa
        }
    }
}
</script>

