import Vue from 'vue'
import VueRouter from 'vue-router'
import device from 'vue-device-detector'
import {ErrorCodes} from '@/utils/enums'
import {kioskRoutes} from '@/router/kiosk'
import {patientSurveyBeforeEnter} from '@/modules/routeHooks'
import {npsRoutes} from '@/router/npsRoutes'

Vue.use(VueRouter)
Vue.use(device)


const routes = [
    {
        path: '/',
        redirect: '/PatientSurvey'
    },
    {
        path: '/preSurvey',
        redirect: '/PatientSurvey'
    },
    {
        path: '/Error',
        name: 'error',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "error-view" */ '@/views/shared/ErrorView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/ThankYou',
        name: 'thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/Form',
        name: 'FormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/FormView')
    },
    /**
     * @deprecated move all urls to the above as we are moving away from massive query_string params
     */
    {
        path: '/PatientSurvey',
        name: 'PatientSurveyView-old',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "patient-survey-view" */ '@/views/shared/PreSurveyView'),
        meta: {layout: 'empty'},
        beforeEnter: patientSurveyBeforeEnter
    },
    {
        path: '/DynamicForm',
        name: 'DynamicFormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pre-survey-view" */ '@/views/shared/DynamicFormView'),
        meta: {layout: 'empty'},
        beforeEnter: (to, from, next) => {
            // we check if the form isn't external and it's not from the actual previous survey AND it's not the full survey
            // if it's not, we go back to the PreSurvey for now...
            if (from.name?.indexOf('PatientSurveyView') === -1 && !to.query.ext && !to.query.fs) next({name: 'PatientSurveyView-old', params: to.params, query: to.query})
            else next()
        }
    },
    {
        path: '/:shortName/PatientSurvey',
        name: 'PatientSurveyView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "patient-survey-view" */ '@/views/shared/PreSurveyView'),
        meta: {layout: 'empty'},
        beforeEnter: patientSurveyBeforeEnter
    },
    {
        path: '/:shortName/Form',
        name: 'Survey-DynamicFormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pre-survey-view" */ '@/views/shared/DynamicFormView'),
        meta: {layout: 'empty'},
        beforeEnter: (to, from, next) => {
            //TODO: rethink this!!
            // // we check if the form isn't external and it's not from the actual previous survey AND it's not the full survey
            // // if it's not, we go back to the PreSurvey for now...
            // if (from.name !== 'PatientSurveyView' && !to.query.ext && !to.query.fs) next({name: 'PatientSurveyView', params: to.params})
            // else next()

            next()
        }
    },
    {
        path: '/:shortName/DynamicForm',
        name: 'ShortName-DynamicFormView',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "pre-survey-view" */ '@/views/shared/DynamicFormView'),
        meta: {layout: 'empty'},
        beforeEnter: (to, from, next) => {
            // we check if the form isn't external and it's not from the actual previous survey AND it's not the full survey
            // if it's not, we go back to the PreSurvey for now...
            if ((from.name !== 'PatientSurveyView' || from.name !== 'PatientSurveyView-old') && !to.query.ext && !to.query.fs) next({name: 'PatientSurveyView', params: to.params, query: to.query})
            else next()
        }
    },
    {
        path: '/:shortName/ThankYou',
        name: 'Survey-thank-you',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/shared/ThankYouView'),
        meta: {layout: 'empty'},
    },
    {
        path: '/Form/edit',
        name: 'FormEdit',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "form-view" */ '@/views/admin/AdminView')
    },
    ...kioskRoutes,
    ...npsRoutes
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

// adding in a global per route guard for now, this might change later
// we are checking to see if we were referred correctly, otherwise send to an error page
router.beforeEach((to, from, next) => {

    const tenantId = to.query.tenant || to.query?.ti
    if (to.name !== 'Error' &&
        to.name !== 'DynamicFormView' &&
        to.name === 'PreSurveyView' &&
        to.name !== 'FormEdit' &&
        !tenantId
    ) next({ name: 'error', params: {type: ErrorCodes.IncorrectReferral} })
    else next()
})

export default router
