<template lang="pug">
    .relative.p-4
        h2.text-lg.text-grey-1 {{ heading }}
        p {{ body }}
        v-btn(type="primary" @click="clickHandler") {{ buttonText }}
</template>

<script>
import { createNamespacedHelpers } from 'vuex-composition-helpers'
const { useState } = createNamespacedHelpers('app')

export default {
    name: 'ModalDefault',
    setup() {
        const { modal: { componentData, isVisible } } = useState(['modal'])

        const heading = computed(() => {
            return componentData.value?.heading || ''
        })

        const body = computed(() => {
            return componentData.value?.body || ''
        })

        const clickHandler = () => {
            console.log('click handler')
        }

        return {
            isVisible,
            heading,
            body,
            clickHandler
        }
    }
}
</script>
